<template>
  <div class="editWorker">
    <!-- 人员信息 -->
    <el-form ref="form" :model="form" class="content property" label-position="top" :rules="rules">
      <el-form-item class="item" label="部门" prop="staffDepartment">
        <el-input v-model="form.staffDepartment" clearable/>
      </el-form-item>
      <el-form-item class="item" label="工号" prop="staffNo">
        <el-input v-model="form.staffNo" clearable/>
      </el-form-item>
      <el-form-item class="item" label="姓名" prop="staffName">
        <el-input v-model="form.staffName" clearable/>
      </el-form-item>
      <el-form-item class="item" label="岗位技能" prop="jobTitle">
        <el-input v-model="form.jobTitle" clearable/>
      </el-form-item>
      <el-form-item class="item" label="日历方案" prop="workshiftSchemeName">
        <el-select v-model="form.workshiftSchemeName" filterable placeholder="关联工作日历">
          <el-option 
            v-for="(v,i) in maintenancePlanList" 
            :key="i" :value="v.workshiftSchemeName" :label="v.workshiftSchemeName"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="item" label="状态" prop="staffStatus">
        <el-radio v-model="form.staffStatus" label="出勤">出勤</el-radio>
        <el-radio v-model="form.staffStatus" label="借调">借调</el-radio>
        <el-radio v-model="form.staffStatus" label="休息">休息</el-radio>
      </el-form-item>
    </el-form>
    <!-- 人员借调/休息情况 -->
    <div class="restInfo" v-if="['借调','休息'].includes(form.staffStatus)">
      <div class="title">人员借调/休息情况</div>
      <el-table :data="restData" header-cell-class-name="headerStyle" style="margin:10px auto">
        <el-table-column v-for="(item) in restColumn" :key="item.label" v-bind="item">
          <template slot-scope="scope">
            <component 
            v-if="item.type!=='index'" 
            :is="item.component" 
            v-model="restData[scope.$index][item.prop]"
            v-bind="item.attribute"
            @change="changeValue(scope.$index,item)"
          >
            <img class="delbtn"
              v-if="item.label==='操作'" 
              src="@/assets/images/dataManage/del.png"
              @click="deleteItem('restData',scope.$index)"
            />
            <span v-else>{{scope.row[item.prop]}}</span>
            </component>
            <span v-else>{{scope.$index+1}}</span>
          </template>
          <template slot="header" v-if="item.renderHead">
            <span class="renderHead">{{item.label}}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-button size="small" @click="addItem('restData')">新增</el-button>
    </div>
    <!-- 人员技能 -->
    <div class="skillInfo">
      <div class="title">人员技能</div>
      <el-table :data="skillData" header-cell-class-name="headerStyle" style="margin:10px auto">
        <template  v-for="(item,index) in skillColumn">
          <el-table-column v-bind="item" :key="index" v-if="!item.show || item.show.includes(form.category)">
            <template slot-scope="scope">
              <component 
                v-if="item.type!=='index'" 
                :is="item.component"
                v-bind="item.attribute"
                v-model="skillData[scope.$index][item.prop]"
                @input="validateInput(scope.$index,item)"
              >
                <img class="delbtn"
                  v-if="item.label==='操作'" 
                  src="@/assets/images/dataManage/del.png"
                  @click="deleteItem('skillData',scope.$index)"
                />
                <template v-if="item.label==='优先级'">
                  <el-option v-for="(item,index) in priorityList" :key="index" :value="item"></el-option>
                </template>
                <template v-else-if="item.label==='装配机型'">
                  <el-option v-for="(item,index) in equipmentTypeList" :key="index" :value="item"></el-option>
                </template>
                <template v-else-if="item.label==='擅长工序'">
                  <el-option v-for="(item,index) in procedureList" :key="index" :value="item"></el-option>
                </template>
              </component>
              <span v-else>{{scope.$index+1}}</span>
            </template>
            <template slot="header" v-if="item.renderHead">
              <span class="renderHead">{{item.label}}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <el-button size="small" @click="addItem('skillData')">新增</el-button>
    </div>
    <div class="footer">
      <el-button @click="$router.push('/produceResource')">取消</el-button>
      <el-button type="primary" @click="checkData">确认</el-button>
    </div>
  </div>
</template>

<script>
import { getWorkshift,getStaffDetail,addStaff,editStaff,getAssemblyCraftList,getAssemblyProductList } from '@/api/data.js'
import slotCom from '../slotCom.vue'
import {isComfirm2} from '@/utils/data.js'
import { mapState } from 'vuex'
export default {
  components: {
    slotCom
  },
  data(){
    return {
      form:{
        staffDepartment:'',
        staffNo:'',
        staffName:'',
        jobTitle:'',
        workshiftSchemeName:'',
        staffStatus:'出勤'
      },
      rules:{
        staffDepartment:[
          { required: true, message: '请输入', trigger: ['blur','change'] }
        ],
        staffNo:[
          { required: true, message: '请输入', trigger: ['blur','change'] }
        ],
        staffName:[
          { required: true, message: '请输入', trigger: ['blur','change'] }
        ],
        jobTitle:[
          { required: true, message: '请输入', trigger: ['blur','change'] }
        ],
        workshiftSchemeName:[
        { required: true, message: '请选择', trigger: ['blur','change'] }
        ],
        staffStatus:[
          { required: true, message: '请选择', trigger: ['blur','change'] }
        ]
      },
      maintenancePlanList:[],
      restColumn:[
        {
          label:'序号',
          prop:'id',
          type:'index',
          width:100,
        },{
          label:'开始-结束时间',
          prop:'maintenanceTime',
          component:'el-date-picker',
          attribute:{
            type:"datetimerange",
            'range-separator':"~",
            'start-placeholder':"开始时间",
            'end-placeholder':"结束时间",
            'value-format':"yyyy-MM-dd HH:mm:ss",
            'format':'yyyy-MM-dd HH:mm'
            },
          renderHead:true,
          width:600,
        },{
          label:'时长',
          prop:'duration',
          component:'slotCom',
        },{
          label:'操作',
          fixed:'right',
          width:200,
          component:'slotCom',
        }
      ],
      restData:[{
        "maintenanceTime":"",
        "endTime": "",
        "startTime": "",
        "duration": ""
      }],
      skillColumn:[
        {
          label:'序号',
          prop:'id',
          type:'index',
          width:100,
        },{
          label:'优先级',
          prop:'priority',
          component:'el-select',
          'min-width':120
        },{
          label:'装配机型',
          prop:'assemblyModel',
          component:'el-select',
          'min-width':120
        },{
          label:'擅长工序',
          prop:'skilledProcesses',
          component:'el-select',
          'min-width':120
        },{
          label:'技能系数',
          prop:'skillFactors',
          component:'el-input',
          'min-width':120
        },{
          label:'操作',
          fixed:'right',
          width:200,
          component:'slotCom',
        }
      ],
      skillData:[{
        "priority":'',
        "assemblyModel":'',
        'skilledProcesses':'',
        'skillFactors':''
      }],
      priorityList:[1,2,3,4,5,6,7,8,9,10],
      equipmentTypeList:[],
      procedureList:[]
    }
  },
  computed:{
    ...mapState(['apsRoleNumber'])
  },
  activated(){
    this.getWorkList()
    if(this.$route.query.id){
      this.resetData()
      this.getDetails(this.$route.query.id)
    }else{
      Object.assign(this.$data,this.$options.data())
      this.$nextTick(()=>{
        this.$refs['form'].clearValidate()
      })
    }
    this.getEquipmentTypeList()
    this.getProcedureList()
  },
  methods:{
    resetData(){
      this.restData = [{
        "maintenanceTime":"",
        "endTime": "",
        "startTime": "",
        "duration": ""
      }]
      this.skillData = [{
        "priority":'',
        "assemblyModel":'',
        'skilledProcesses':'',
        'skillFactors':''
      }]
    },
    // 获取机型列表
    getEquipmentTypeList(){
      getAssemblyProductList().then(res=>{
        if(res.code === 0){
          this.equipmentTypeList = res.data
        }
      })
    },
    // 获取工序列表
    getProcedureList(){
      getAssemblyCraftList().then(res=>{
        if(res.code === 0){
          this.procedureList = res.data
        }
      })
    },
    // 查看详情
    getDetails(id){
      getStaffDetail(id).then(res=>{
        if(res.code === 0){
          for(let key in this.form){
            this.$set(this.form,key,res.data[key])
          }
          this.skillData = res.data.skills || this.skillData;
          const loanedRecords = res.data.loanedRecords?.map(v=>{
            return {
              ...v,
              maintenanceTime:[v.startTime,v.endTime]
            }
          })
          if(loanedRecords) this.restData = loanedRecords
        }
      })
    },
    // 获取日历方案列表
    getWorkList(){
      let params = {
        page:1,
        size:999
      }
      getWorkshift(params).then(res=>{
        if(res.code === 0){
          this.maintenancePlanList = res.data.records
        }else{
          this.$message({
            message:res.msg || '接口出错',
            showClose: true,
            duration: 0,
            type:'error'
          })
        }
      }).catch(()=>{
        this.$message({
          message:'接口出错',
          showClose: true,
          duration: 0,
          type:'error'
        })
      })
    },
    changeValue(index,item){
      if(item.label=='开始-结束时间'){
        // 计算保养时长
        let maintenanceTime = this.restData[index].maintenanceTime
        let time = this.convertMsToTime(maintenanceTime)
        this.$set(this.restData[index],'duration',time || '')
        this.$set(this.restData[index],'startTime',maintenanceTime?.[0] || '')
        this.$set(this.restData[index],'endTime',maintenanceTime?.[1] || '')
      }
    },
    // 删除
    deleteItem(key,index){
      console.log('删除',{
        [key]:index
      })
      this[key].splice(index,1)
    },
    // 计算时长
    convertMsToTime(timeRange) {
      if(!timeRange) return
      let milliseconds = new Date(timeRange[1]).getTime() - new Date(timeRange[0]).getTime()
      var hours = Math.floor(milliseconds / (1000 * 60 * 60));
      var minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
      // var seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
      console.log('计算时长',timeRange,hours + "时 " + minutes + "分 ")
      return hours + "时 " + minutes + "分 ";
    },
    // 新增
    addItem(key){
      // this[key].push(this.$options.data()[key][0])
      if(key==='restData'){
        this[key].push({
        "maintenanceTime":"",
        "endTime": "",
        "startTime": "",
        "duration": ""
        })
      }else{
        this[key].push({
          "priority":'',
          "assemblyModel":'',
          'skilledProcesses':'',
          'skillFactors':''
        })
      }
    },
    // 输入正浮点数
    validateInput(index,item){
      if(item.component!=='el-input'){
        return false
      }
      let regex = /^[0-9]*$/;
      let val = this.skillData[index][item.prop]
      if(!regex.test(val)){
        let value = val.replace(/[^\d.]/g, '').replace(/^(\-)*(\d+)\.(\d{2}).*$/, '$1$2.$3').replace(/^0+(\d+\.\d{2}).*$/, '$1').replace(/^0+(\d).*$/, '$1')
        this.$set(this.skillData[index],[item.prop],value)
      }
    },
    // 校验数据
    async checkData(){
      let result1
      let result2 = this.form.staffStatus === '出勤' ? true : this.restData.every(v=>v.maintenanceTime)
      await this.$refs['form'].validate((valid) => {
        result1 = valid
      });
      if(this.form.staffStatus !== '出勤' && (!this.restData.length || !result2)){
        return this.$message({
          message:'请填写人员借调/休息情况',
          showClose: true,
          duration: 0,
          type:'warning'
        })
      }
      if(!result2){
        return this.$message({
          message:'请选择开始-结束时间',
          showClose: true,
          duration: 0,
          type:'warning'
        })
      }
      if(result1 && result2) this.onSave()
    },
    // 保存
    onSave(){
      let Api = addStaff
      let params = {}
      if(this.$route.query.id){
        params.id = this.$route.query.id
        Api = editStaff
      }
      Object.assign(params,
      {
        ...this.form,
        loanedRecords: this.form.staffStatus === '出勤' ? null : this.restData.map(({maintenanceTime,...rest})=>rest),
        // loanedRecords: this.form.staffStatus === '出勤' ? null : this.restData,
        skills:this.skillData,
        shiftId:this.maintenancePlanList.find(v=>v.workshiftSchemeName==this.form.workshiftSchemeName)?.shiftId
      })
      Api(params).then(res=>{
        if(res.code == 0){
          this.$message.success('操作成功')
          if(this.apsRoleNumber ==1 || this.apsRoleNumber ==2){
            isComfirm2({
              title: '是否去更新计划？',
              iconClass: 'el-icon-warning',
              customClass: 'errorMessage',
              confirmButtonText:'确认'
            },()=>{
              this.$router.push('/ganttProduction')
            },()=>{
              this.$router.push('/produceResource')
            })
          }
        }else{
          this.$message({
            message: res.msg || '接口失败',
            showClose: true,
            duration: 0,
            type:'error'
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.editWorker{
  text-align: left;
  padding: 18px 28px 50px;
  font-size: 16px;
  color:#E8E8E8;
  @mixin common{
    padding: 20px 26px;
    width: 100%;
    min-height: 100px;
    background: #232323;
    border-radius: 8px;
    margin-bottom: 10px;
    box-sizing: border-box;
  }
  .content{
    @include common();
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-column-gap: 46px;
    padding-right: 100px;
    .pro,.title{
      grid-column: span 4;
      .el-select{
        width: calc((100% - 52px)/4 - 23px);
      }
    }
  }
  .restInfo,.skillInfo{
    @include common();
    .renderHead{
      &:before{
        content: "*";
        color: #F56C6C;
        margin-right: 5px
      }
    }
  }
}
</style>
<style scoped lang="scss">
@import "../../style/index.scss";
</style>